<script setup>
import { AdvancedImage, placeholder, lazyload } from '@cloudinary/vue'
import { Cloudinary } from '@cloudinary/url-gen'
import { defineProps } from 'vue'





const props = defineProps({
  publicId: {
    type: String,
    required: true
  }
})

const cld = new Cloudinary({
  cloud: {
    cloudName: `${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}`
  }
})
const myImage = cld
  .image(props.publicId)
const plugins = [lazyload({rootMargin: '10px 20px 10px 30px', treshold: 0.15}), placeholder({mode:"blur"})]
</script>

<template>
  <AdvancedImage
    :cldImg="myImage"
    
    :plugins="plugins"
    
    />
</template>