import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './styles/global.scss'

// import json files with strings for i18n
import langPT from './languages/pt.json';
import langEN from './languages/en.json';

// the file we created above with `database`, `firestore` and other exports
import { initializeApp } from 'firebase/app'
import { getFirestore, collection} from 'firebase/firestore'
// ... other firebase imports
export const firebaseApp = initializeApp({
    apiKey: "AIzaSyCrG9w26olwviaFg6TYNvz_pRYmaiLputw",
    authDomain: "evont-26ddb.firebaseapp.com",
    projectId: "evont-26ddb",
    storageBucket: "evont-26ddb.appspot.com",
    messagingSenderId: "1032701078793",
    appId: "1:1032701078793:web:e74ac9659a2e83e91637d8",
    measurementId: "G-VTJV7DW5ZY"
})

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faDiscord, faXTwitter, faInstagram, faFacebook, faYoutube, faTwitch } from "@fortawesome/free-brands-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

library.add(faBars, faDiscord, faXTwitter, faInstagram, faFacebook, faEnvelope, faYoutube, faTwitch, faXmark);

const i18n = createI18n({
    locale: 'pt',
    fallbackLocale: 'en',
    messages: {
        en: {
            message: langEN
        },
        pt: {
            message: langPT
        }
    },
    globalInjection: true
});

createApp(App)
    .use(store)
    .use(router)
    .use(ElementPlus)
    .use(i18n)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount('#app')
