<template>
    <div class="pickers">
        <a :class="{ active: $i18n.locale === 'pt' }" @click="changeLanguage('pt')">PT</a>
        <span class="separator">|</span>
        <a :class="{ active: $i18n.locale === 'en' }" @click="changeLanguage('en')">EN</a>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "LangPicker",
    data() {
        return {
            langs: ['pt', 'en']
        }
    },
    methods: {
        changeLanguage(lang: string) {
            this.$i18n.locale = lang;
        }
    }
})
</script>

<style>
.pickers {
    padding: 5px;
    width: fit-content;
}

.separator {
    padding: 0 5px 0 5px;
}

a {
    color: white;
    cursor: pointer;
}

a.active {
    font-weight: bolder;
    text-decoration: underline;
}
</style>