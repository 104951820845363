<template>
  <div class="lang_picker"><lang-picker></lang-picker></div>

  <div class="home">
    <div class="centerContainer">
      <div class="border-br enlarge" @click="goTo('/')" @mouseover="upHere = true" @mouseleave="upHere = false">
        <div class="squareContent">
          <transition>
            <img v-if="!upHere" class="mainlogo" alt="Vue logo" src="../assets/Evont-2.svg" />
          </transition>
          <transition>
            <div v-if="upHere" class="sponsorsContainer">
              <div>{{ $t('message.sponsoredby') }}</div>
              <div class="logosContainer">
                <img class="sponsorlogo" src="../assets/sponsors/santa-cruz.png" />
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div class="centerSquare border-bl" @click="goTo('/about-us')">
        <div class="squareContent">{{ $t('message.about_us') }}</div>
      </div>
      <div class="centerSquare border-tr" @click="goTo('/tournaments')">
        <div class="squareContent">{{ $t('message.tournaments') }}</div>
      </div>
      <div class="centerSquareCarol border-tl carrouselBox hardIron">
        <div class="slideContainer">
          <carousel :items-to-show="1" :autoplay="3000" :wrap-around="true">
            <slide v-for="slide in 1" :key="slide">
              <img class="slideImg" src="@/assets/Imagenscarrocel/img1.jpg" />
            </slide>
            <slide v-for="slide in 1" :key="slide">
              <img class="slideImg" src="@/assets/Imagenscarrocel/img2.jpg" />
            </slide>
            <slide v-for="slide in 1" :key="slide">
              <img class="slideImg" src="@/assets/Imagenscarrocel/img3.jpg" />
            </slide>
            <slide v-for="slide in 1" :key="slide">
              <img class="slideImg" src="@/assets/Imagenscarrocel/img4.jpg" />
            </slide>
            <slide v-for="slide in 1" :key="slide">
              <img class="slideImg" src="@/assets/Imagenscarrocel/img5.jpg" />
            </slide>
            <slide v-for="slide in 1" :key="slide">
              <img class="slideImg" src="@/assets/Imagenscarrocel/img6.jpg" />
            </slide>
            <slide v-for="slide in 1" :key="slide">
              <img class="slideImg" src="@/assets/Imagenscarrocel/img7.jpg" />
            </slide>
            <slide v-for="slide in 1" :key="slide">
              <img class="slideImg" src="@/assets/Imagenscarrocel/img8.jpg" />
            </slide>
            <slide v-for="slide in 1" :key="slide">
              <img class="slideImg" src="@/assets/Imagenscarrocel/img9.jpg" />
            </slide>
            <slide v-for="slide in 1" :key="slide">
              <img class="slideImg" src="@/assets/Imagenscarrocel/img10.jpg" />
            </slide>
            <slide v-for="slide in 1" :key="slide">
              <img class="slideImg" src="@/assets/Imagenscarrocel/img11.jpg" />
            </slide>
            <slide v-for="slide in 1" :key="slide">
              <img class="slideImg" src="@/assets/Imagenscarrocel/img12.jpg" />
            </slide>

            <template #addons>
              <navigation />
              <!-- <pagination /> -->
            </template>
          </carousel>
        </div>
      </div>
    </div>
    <!-- <img alt="Vue logo" src="../assets/Evont-2.svg" />
    <img alt="Vue logo" src="../assets/Evont-2.svg" />
    <img alt="Vue logo" src="../assets/Evont-2.svg" />
    <img alt="Vue logo" src="../assets/Evont-2.svg" /> -->
    <div class="socialBar">
      <div class="socialNetworkContainer">
        <a target="_blank"  href="https://discord.gg/YKbCVphJku"><font-awesome-icon :icon="['fab', 'discord']" /></a>
      </div>
      <div class="socialNetworkContainer">
        <a target="_blank" href="https://twitter.com/EvontEvents"><font-awesome-icon :icon="['fab', 'fa-x-twitter']" /></a>
      </div>
      <div class="socialNetworkContainer">
        <a target="_blank" href="https://www.instagram.com/evontevents/"><font-awesome-icon :icon="['fab', 'fa-instagram']" /></a>
      </div>
      <div class="socialNetworkContainer">
        <a target="_blank" href="https://www.facebook.com/profile.php?id=61561428173212"><font-awesome-icon
            :icon="['fab', 'fa-facebook']" /></a>
      </div>
      <div class="socialNetworkContainer">
        <a target="_blank" href="mailto:geral@evont.pt"><font-awesome-icon :icon="['fas', 'fa-envelope']" /></a>
      </div>
      <div class="socialNetworkContainer">
        <a target="_blank" href="https://www.youtube.com/@EVONTEVENTS"><font-awesome-icon :icon="['fab', 'fa-youtube']" /></a>
      </div>
      <div class="socialNetworkContainer">
        <a target="_blank" href="https://www.twitch.tv/evontournaments"><font-awesome-icon :icon="['fab', 'fa-twitch']" /></a>
      </div>
    </div>
  </div>

</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import router from "vue-router";
import { useLazyload } from 'vue3-lazyload'
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import LangPicker from "@/components/langPicker.vue";

export default defineComponent({
  name: "tournamentsView",
  components: { Carousel, Slide, Navigation, LangPicker },
  setup() {
    const src = ref('./../assets/Evont-4.svg')
    const lazyRef = useLazyload(src, {
      lifecycle: {
        loading: () => {
          // console.log('loading')
        },
        error: () => {
          // console.log('error')
        },
        loaded: () => {
          // console.log('loaded')
        }
      }
    })
    return {
      lazyRef
    }
  },
  data() {
    return {
      upHere: false,
    };
  },
  methods: {
    goTo(path: string) {
      this.$router.push(path);
    },
  },
});
</script>

<style scoped>
.home {
  /* padding: 50px; */
  margin: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
}

img {
  max-height: 50vh;
}

.centerContainer {
  height: 45vh;
  aspect-ratio: 1/1;
  display: flex;
  flex-wrap: wrap;
  transform: rotate(45deg);
  gap: 5px;
}

.centerSquare {
  width: 49%;
  height: 49%;
  background-color: #bb343c;
  /* border: 3px solid red; */
  box-sizing: border-box;
  font-weight: 600;
  border-width: 3px;
  /* border-color: #a22330; */
  border-color: #f39ba4;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  transition: transform 0.4s;
}

.centerSquare:hover {
  width: 49%;
  height: 49%;
  background-color: #bb343c;
  /* border: 3px solid red; */
  box-sizing: border-box;
  border-width: 3px;
  border-color: white;
  border-style: none;
  transform: scale(1.3);
  transition: transform 0.4s;
  z-index: 4;
}

.centerSquareCarol {
  width: 49%;
  height: 49%;
  background-color: #bb343c;
  /* border: 3px solid red; */
  box-sizing: border-box;
  font-weight: 600;
  border-width: 3px;
  /* border-color: #a22330; */
  border-color: #f39ba4;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  transition: transform 0.4s;
}


.border-tl {
  border-top-style: solid;
  border-left-style: solid;
}

.border-tr {
  border-top-style: solid;
  border-right-style: solid;
}

.border-bl {
  border-bottom-style: solid;
  border-left-style: solid;
}

.border-br {
  border-bottom-style: solid;
  border-right-style: solid;
}

.squareContent {
  margin: 5px;
  height: 100%;
  width: 100%;
  transform: rotate(-45deg);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  position: relative;
  text-transform: uppercase;
}

.mainlogo {
  width: 145%;
  transform: translate(0, -5%);
  z-index: 5;
  position: absolute;
}

.enlarge {
  transition: transform 0.4s;
  scale: 1.3;
  width: 49%;
  height: 49%;
  background-color: #bb343c;
  /* border: 3px solid red; */
  box-sizing: border-box;
  font-weight: 600;
  border-width: 3px;
  /* border-color: #a22330; */
  border-color: #f39ba4;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  z-index: 2;
}

.enlarge:hover {
  transform: scale(1.3);
  transition: all 0.4s;
  /* scale: 1.8; */
  width: 49%;
  height: 49%;
  background-color: #bb343c;
  /* border: 3px solid red; */
  box-sizing: border-box;
  font-weight: 600;
  border-width: 3px;
  /* border-color: #a22330; */
  border-color: #f39ba4;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  z-index: 2;
}

.slideImg {
  max-width: 100%;
  height: 100%;
  object-fit: fill;
  overflow: hidden;
}

.slideContainer {
  /* overflow: hidden; */
  transform: rotate(-45deg) translate(0%, -23%);
  width: 150%;
  height: 150%;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.carrouselBox {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  color: white !important;
}

svg {
  color: white !important;
}

.carousel__icon {
  color: white !important;
}

.socialBar {
  background-color: #141414;
  background-size: 100%;
  background-blend-mode: multiply;
  background-image: url("./../assets/dark-brushed-metal-texture.jpg");
  padding: 0 30px;
  height: 50px;
  width: 350px;
  margin-top: 124px;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  position: fixed;
  bottom: 0;
  clip-path: polygon(5% 0, 95% 0%, 100% 100%, 0% 100%);
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
}

.socialNetworkContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white !important;
}

.socialNetworkContainer a {
  cursor: pointer;
}

.socialNetworkContainer a:hover {
  color: var(--el-menu-active-color) !important;
}

.socialNetworkContainer svg {
  color: inherit !important;
}

a {
  color: unset;
}

.carousel__next,
.carousel_prev {
  color: white !important;
}

.sponsorsContainer {
  position: absolute;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}

.logosContainer {
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;
  max-width: 95vw;
}

.sponsorlogo {
  width: 70%;
  max-width: 150px;
}


.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.lang_picker {
  position: fixed;
  margin-left: 15px;
  margin-top: 15px;
}

/*MOBILE STUFF ONLY*/
@media only screen and (max-width: 600px) {
  .home {
    padding-top: 5vh;
    min-height: 100vh;
    display: flex;
    justify-content: flex-start;
  }

  .centerContainer {
    height: 85vh;
    width: 95vw;
    transform: unset !important;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
  }

  .enlarge {
    scale: 1;
    border: solid white;
    width: 100%;
  }

  .enlarge:hover {
    transform: none;
    transition: none;
    width: 100%;
  }

  .mainlogo {
    width: 80%;
  }

  .centerSquare {
    border: solid white;
    width: 100%;
    height: 22vh;
  }

  .centerSquare:hover {
    width: 100%;
    height: 18vh;
    border: solid white;
    transform: none;
    transition: none;
  }

  .centerSquareCarol {
    border: solid white;
    width: 100%;
    height: 22vh;
  }

  .centerSquareCarol:hover {
    width: 100%;
    height: 18vh;
    border: solid white;
    transform: none;
    transition: none;
  }

  .squareContent {
    transform: unset !important;
    margin: 0;
  }

  .slideContainer {
    transform: unset;
    width: 100%;
    height: min-content;

  }

  .carrouselBox {
    overflow: hidden;
    height: 42.5vh !important;
  }

  .carousel__next,
  .carousel_prev {
    color: white !important;
  }
}
</style>