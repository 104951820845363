import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import Galeria from '@/views/GaleriaView.vue'
import HomeView from '@/views/HomeView.vue'
import tournamentsView from '@/views/TournamentsView.vue'
import ResultsView from '@/views/ResultsView.vue'
import AboutView from '@/views/AboutView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/tournaments',
    name: 'tournaments',
    component: tournamentsView
  },
  {
    path: '/results',
    name: 'results',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ResultsView
  },
  {
    path: '/about-us',
    name: 'about-us',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AboutView
  },
  {
    path: '/galeria',
    name: 'galeria',
    component: Galeria
  }, 
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: HomeView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
