<template>
  <div class="" v-if="showSideMenu">
    <el-menu
      class="el-menu-demo sidebar"
      mode="vertical"
      :ellipsis="false"
      :default-active="activeIndex"
    >
      <!-- <img style="max-height: 90%" src="./../assets/Evont.svg" alt="Evont logo" /> -->

      <div class="mobileIconContainer" @click="closeSideBar">
        <font-awesome-icon :icon="['fas', 'xmark']" />
      </div>


      <el-menu-item index="/tournaments">
        <!-- <RouterLink to="/tournaments" @click="closeSideBar">Tournaments</RouterLink> -->
        <a @click="navToPage('/tournaments')">{{ $t('message.tournaments') }}</a>
      </el-menu-item>

      <el-menu-item index="/about-us" >
        <!-- <RouterLink to="/about-us" @click="closeSideBar">About Us</RouterLink> -->
        <a @click="navToPage('/about-us')">{{ $t('message.about_us') }}</a>
      </el-menu-item>

      <el-menu-item index="/galeria">
        <!-- <RouterLink to="/galeria" @click="closeSideBar">galeria</RouterLink> -->
        <a @click="navToPage('/Galeria')">{{ $t('message.galeria') }}</a>
      </el-menu-item>
    </el-menu>
    <div class="sideBarFog" @click="closeSideBar"></div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits } from "vue";
import { useRouter } from "vue-router";

const emit = defineEmits(["sidemenuNav", "closeSideBar"]);
const router = useRouter();
const props = defineProps(["showSideMenu", "location"]);
const activeIndex = ref(props.location);
function goToHome() {
emit("sidemenuNav");
router.push("/");
}

function navToPage(page){
  emit("closeSideBar");
  router.push(page);
}

function closeSideBar() {
emit("closeSideBar");
}
</script>

<style scoped>
.sidebar {
position: fixed !important;
height: 100vh;
max-width: 80vw;
right: 0;
top: 0;
z-index: 99;
-webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
-moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
}

.navlogo {
cursor: pointer;
max-width: 169px;
padding-right: 8px;
padding-left: 8px;
height: var(--el-menu-horizontal-height);
}

.flex-grow {
flex-grow: 1;
}

li {
padding: 0 0 !important;
}

a {
text-decoration: none;
height: 100%;
width: 100%;
padding: 0 20px;
}

.el-menu {
/* background-color: #e1e1e1 !important; */
background-color: #0e0e0e;
color: white;
z-index: 99;
}

.el-menu-item a {
/* border-bottom: 1px solid #c7c7c7 !important; */
border-bottom: 1px solid #0a0808 !important;
color: var(--el-menu-active-color) !important;
}

.el-menu--vertical > .el-menu-item.is-active {
border-left: 2px solid var(--el-menu-active-color);
color: var(--el-menu-active-color) !important;
}

.mobileIconContainer {
margin-top: 5px;
display: block;
margin-left: 65%;
margin-right: auto;
font-size: 40px;
align-items: right;
justify-content: right;
cursor: pointer;
color: var(--el-menu-active-color);
}

.sideBarFog {
width: 100vw;
height: 100vh;
opacity: 69%;
background-color: black;
position: fixed;
top: 0;
left: 0;
z-index: 98;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
opacity: 0;
}
</style>