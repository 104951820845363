<template>
    <div class="about">
        <EvontNavbar location="/galeria"></EvontNavbar>
        <div class="">
          <el-menu
            :default-active="sidemenuIndex"
            class="el-menu-vertical-demo"
            @select="handleOpen"
          >
            <img
              @click="goToHome()"
              class="navlogo"
              src="./../assets/Evont-4.svg"
              alt="Evont logo"
            />
            <el-menu-item index="0">
              <template #title> 
                <div class="desktopOnly">Tekken 8 #2 </div>
                <div class="mobileOnly">T8 #2 </div>
              </template>
            </el-menu-item>

            <el-menu-item index="1">
              <template #title> 
                <div class="desktopOnly">GG Strive #1 </div>
                <div class="mobileOnly">GG #1 </div>
              </template>
            </el-menu-item>

            <el-menu-item index="2">
              <template #title> 
                <div class="desktopOnly">Digimon #1 </div>
                <div class="mobileOnly">DG #1 </div>
              </template>
            </el-menu-item>

            <el-menu-item index="3">
              <template #title> 
                <div class="desktopOnly">Tekken 8 #1 </div>
                <div class="mobileOnly">T8 #1 </div>
              </template>
            </el-menu-item>
          </el-menu>
        </div>
        
        <div class="aboutContent" v-if="sidemenuIndex == '0'">
           <ListagemImagens tagsName="Tekken8-2"/>
          </div>
        <div class="aboutContent" v-if="sidemenuIndex == '1'">
           <ListagemImagens tagsName="Strive-1"/>
          </div>
        <div class="aboutContent" v-if="sidemenuIndex == '2'">
           <ListagemImagens tagsName="Digimon-1"/>
          </div>
        <div class="aboutContent" v-if="sidemenuIndex == '3'">
           <ListagemImagens tagsName="Tekken8-1"/>
          </div>
        </div>
    </template>
    
    <script lang="ts">


    
    import ListagemImagens from '@/components/ListagemImagens.vue';
    import { defineComponent } from "vue";
    import EvontNavbar from "../components/evontNavbar.vue";
    export default defineComponent({
      name: "App",
      components: { EvontNavbar, ListagemImagens },
      data(){
        return {
          sidemenuIndex: "0",
        };
      },
      methods: {
        handleOpen(key: string) {
          this.sidemenuIndex = key.toString();
        },
    
        goToHome() {
          this.$router.push("/");
        },
        
    },
    })
    </script>
    
    <style scoped>
    .navlogo {
      cursor: pointer;
      max-width: 80%;
      padding-left: 8px;
      min-height: var(--el-menu-horizontal-height);
    }
    .el-menu-vertical-demo:not(.el-menu--collapse) {
      /* width: 200px; */
      width: 25vw;
      max-width: 200px;
      height: 100vh;
      top: 0px;
      position: fixed;
    }
    .about {
      padding: 80px 0px 60px 0px;
      height: 100%;
      min-height: 100vh;
    }
    .el-menu {
      /* background-color: #e1e1e1 !important; */
      background-color: #0e0e0e !important;
    }
    .is-active {
      border-right: 2px solid var(--el-menu-active-color);
      background-color: #49030c;
    }
    
    .aboutContent {
      padding-left: 300px;
    }
    
    
 @media only screen and (max-width: 600px) {


  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 15vw;
    height: 100vh;
    top: 0px;
    position: fixed;
  }


  .aboutContent {
    padding-left: 16vw;
  }

  .desktopOnly{
    display: none;
  }

 }
</style>