<template>
  <div class="results">
    <!-- <img alt="Vue logo" src="../assets/EvontFull.png" /> -->
    <div>
      <h2>RESULTS</h2>
      <el-menu
        :default-active="sidemenuIndex"
        class="el-menu-vertical-demo"
        @select="handleOpen"
      >
        <img
          @click="goToHome()"
          class="navlogo"
          src="./../assets/Evont-4.svg"
          alt="Evont logo"
        />
        <el-menu-item
          v-for="(game, index) in games"
          :index="index.toString() || '0'"
          :key="index || '0'"
        >
          <!-- <el-icon><setting /></el-icon> -->
          <template #title>
            <!-- {{ game.name }} -->
            <img style="width: 100%" :src="game.logo" />
          </template>
        </el-menu-item>
      </el-menu>
    </div>

    <div style="flex: 1; padding-left: 210px">
      <!-- <img style="height:50px;" :src="selectedGame.logo" /> -->

      <el-timeline>
        <el-timeline-item
          placement="top"
          v-for="(activity, index) in selectedGame.results"
          :key="index.toString"
          :timestamp="activity.timestamp"
        >
          <el-card>
            <div class="cardBodyContainer">
              <img :src="activity.content" />

              <div style="flex: 1">
                <el-skeleton :rows="5" />
              </div>
            </div>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
    <!-- <img style="width: 100px" src="./../assets/tournamentResults/GuiltyGear.png" /> -->
  </div>
</template>
  
  <script lang="ts">
import { defineComponent, ref } from "vue";

const games = ref([
  {
    name: "Tekken 8",
    logo: require("@/assets/gameLogos/Tekken-8-logo.png"),
    results: [
      {
        timestamp: "26/05/2024",
        content: require("@/assets/tournamentResults/tekken.png"),
      },
      {
        timestamp: "26/05/2024",
        content: require("@/assets/tournamentResults/tekken.png"),
      },
      {
        timestamp: "26/05/2024",
        content: require("@/assets/tournamentResults/tekken.png"),
      },
    ],
  },
  {
    name: "Digimon TCG",
    logo: require("@/assets/gameLogos/Digimon.png"),
    results: [],
  },
  {
    name: "Guilty Gear Strive",
    logo: require("@/assets/gameLogos/Strive.png"),
    results: [],
  },
]);

export default defineComponent({
  name: "ResultsView",
  data() {
    return {
      sidemenuIndex: "0",
      games: games,
    };
  },
  methods: {
    handleOpen(key: string) {
      this.sidemenuIndex = key.toString();
    },

    goToHome() {
      this.$router.push("/");
    },
  },

  computed: {
    selectedGame(): any {
      return this.games[Number(this.sidemenuIndex)];
    },
  },
});
</script>

<style scoped>
.results {
  padding: 20px 0px 60px 0px;
  /* display: flex; */
  /* gap: 60px; */
  height: 100%;
  /* flex-direction: column; */
  /* background-color: #f6f6f6; */
  /* background-color: #bfbfbf; */
  /* background-color: var(--bg-color); */
  min-height: 100vh;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  height: 100vh;
  top: 0px;
  position: fixed;
}

.el-card {
  width: 90% !important;
  /* height: 50%; */
  display: flex;
  gap: 10px !important;
  flex-direction: row;
  align-items: center;
}

.cardBodyContainer {
  display: flex !important;
  gap: 30px;
  max-height: 50vh;
  width: 100%;
  flex-direction: row;
}
.cardBodyContainer img {
  max-width: 40%;
  object-fit: scale-down;
}

.el-menu {
  /* background-color: #e1e1e1 !important; */
  background-color: #0e0e0e !important;
}
.is-active {
  border-right: 2px solid var(--el-menu-active-color);
  background-color: #49030c;
}

.el-timeline-item__timestamp.is-top {
  color: red !important;
}

.navlogo {
  cursor: pointer;
  max-width: 80%;
  padding: 8px;
}
</style>
  